.more-info-view-container{
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .more-info-view-inner{
    padding: 10px;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
  .more-info-item {
    padding:2px;
    .description-container{
      display: flex;
      flex-direction: row;
      
      .description{
        flex: 1;
      }

      .icon{
        
        img{
          width:47px;
          height: 47px;
          border-radius: 8px;
        }
      }
    }

  }
  .bold {
    font-weight:  bold;
  }
}




